import { Select } from "antd";
import { Visit, VisitMedicine } from "../../interfaces/Visit";
import React from "react";
import { classNames } from "../../utils/CssUtil";
import {
  changeVisitData,
  UpdateVisitField,
} from "../../features/visit/visitSlice";
import { useAppDispatch } from "../../hooks";

type MedicineInstructionsProp = {
  visit: Visit;
};
const MedicineInstructions: React.FC<MedicineInstructionsProp> = ({
  visit,
}) => {
  const dispatch = useAppDispatch();

  const forms = [
    {
      name: "Tablet",
      value: "tablet",
    },

    {
      name: "Capsule",
      value: "capsule",
    },

    {
      name: "Syrup",
      value: "syrup",
    },

    {
      name: "Drops",
      value: "drops",
    },

    {
      name: "Creame",
      value: "creame",
    },

    {
      name: "Lotion",
      value: "lotion",
    },

    {
      name: "gel",
      value: "gel",
    },

    {
      name: "Powder",
      value: "powder",
    },

    {
      name: "Soap",
      value: "soap",
    },
    {
      name: "Inhaler",
      value: "inhaler",
    },
  ];
  const mealTypes = [
    {
      name: "Before Meal",
      value: "before_meal",
    },
    {
      name: "After Meal",
      value: "after_meal",
    },
  ];

  const durations = [
    {
      name: "Days",
      value: "days",
    },
    {
      name: "Weeks",
      value: "weeks",
    },
    {
      name: "Months",
      value: "months",
    },
  ];
  const frequencies = [
    {
      name: "Daily",
      value: "daily",
    },
    {
      name: "Weekly",
      value: "weekly",
    },
    {
      name: "Alternate Days",
      value: "alternate days",
    },
  ];
  const onChange = (value: any, key: string, medicine: any) => {
    const visitMedicines = visit.medicines.map((i) => {
      if (i.medicineId === medicine.medicineId) {
        return {
          ...i,
          instructions: {
            ...i.instructions,
            [key]: value,
          },
        };
      } else {
        return { ...i };
      }
    });
    updateField("medicines", visitMedicines);
  };
  const onRemoveMedicine = (item: VisitMedicine) => {
    const updatedMedicines = visit.medicines.filter(
      (selected) => selected.medicineId !== item.medicineId
    );
    updateField("medicines", updatedMedicines);
  };
  const updateField = (fieldName: keyof Visit, value: any) => {
    const payload: UpdateVisitField = { fieldName, value };
    dispatch(changeVisitData(payload));
  };
  return (
    <div className="px-2 mt-2 outline-none">
      {visit.medicines &&
        visit.medicines.map((item, index) => (
          <div key={item.medicineId} className="p-2">
            <div className="items-center justify-between text-white transition duration-500 cursor-pointer ease">
              <div className="flex flex-row w-full p-2 transition duration-500 rounded-t-md bg-primary basis-1/4 group-focus:text-black ease">
                <span className="w-full font-semibold capitalize">
                  {index + 1}. {item?.instructions?.name}
                </span>

                <span
                  onClick={() => onRemoveMedicine(item)}
                  className="flex justify-end items-center px-3 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800"
                >
                  Remove
                </span>
              </div>
            </div>
            <div className="p-2 duration-500 bg-gray-100 border ease">
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-8">
                <span className="text-sm font-medium text-gray-700">
                  Form
                  <div className="mt-1">
                    <Select
                      style={{ width: "100%" }}
                      onChange={(value) => onChange(value, "form", item)}
                      value={item?.instructions?.form}
                      options={forms}
                    />
                  </div>
                </span>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Duration
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <input
                      type="text"
                      id="duration"
                      value={item?.instructions?.duration}
                      onChange={(e) =>
                        onChange(e.target.value, "duration", item)
                      }
                      className="block w-full py-1 pr-20 text-gray-900 border-0 rounded-md ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center">
                      <label className="sr-only">Duration</label>
                      <select
                        id="frequency"
                        value={item?.instructions?.durationPeriod}
                        onChange={(e) =>
                          onChange(e.target.value, "durationPeriod", item)
                        }
                        className="h-full py-0 pl-2 text-gray-500 bg-transparent border-0 rounded-md pr-7 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                      >
                        {durations.map((i) => (
                          <option key={i.name} value={i.value}>
                            {i.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Frequency
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <span className="text-gray-500 sm:text-sm"> </span>
                    </div>
                    <div>
                      <div className="mt-1">
                        <Select
                          style={{ width: "100%" }}
                          onChange={(value) =>
                            onChange(value, "frequency", item)
                          }
                          value={item?.instructions?.frequency}
                          options={frequencies}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <fieldset className="col-span-2">
                  <legend className="block text-sm font-medium text-gray-700">
                    Dosage
                  </legend>
                  <div className="mt-1 -space-y-px bg-white shadow-sm">
                    <div className="flex -space-x-px">
                      <div className="flex-1 min-w-0">
                        <label className="sr-only">Morning</label>
                        <input
                          name="card-cvc"
                          id="card-cvc"
                          type="number"
                          onChange={(e) =>
                            onChange(e.target.value, "morning", item)
                          }
                          className="relative block w-full py-1.5 bg-transparent border-gray-300 rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                          value={item?.instructions?.morning}
                          placeholder="Morning"
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <label className="sr-only">AfterNoon</label>
                        <input
                          name="card-cvc"
                          id="card-cvc"
                          type="number"
                          className="relative block w-full bg-transparent py-1.5 border-gray-300 rounded-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                          value={item?.instructions?.afternoon}
                          placeholder="AfterNoon"
                          onChange={(e) =>
                            onChange(e.target.value, "afternoon", item)
                          }
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <label className="sr-only">Night</label>
                        <input
                          name="card-cvc"
                          id="card-cvc"
                          type="number"
                          className="relative block w-full py-1.5 bg-transparent border-gray-300 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                          value={item?.instructions?.night}
                          placeholder="Night"
                          onChange={(e) =>
                            onChange(e.target.value, "night", item)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset
                  className="col-span-2"
                  aria-label="Choose a memory option"
                >
                  <div className="flex items-center justify-between">
                    <div className="text-sm font-medium leading-6 text-gray-900">
                      Meal
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-2 ">
                    {mealTypes.map((mealType) => (
                      <label
                        key={`${item.id}-${mealType.value}`}
                        className={classNames(
                          mealType.value === item?.instructions?.meal
                            ? "border-indigo-600 ring-0  "
                            : "border-gray-300 ",
                          "flex items-center border ring-0 ring-offset-0 text-black bg-white justify-center px-4  py-2 text-xs font-semibold uppercase  rounded-md cursor-pointer  sm:flex-1"
                        )}
                      >
                        <span className="flex items-center w-full justify-evenly">
                          <input
                            type="radio"
                            value={item?.instructions?.meal}
                            className={classNames(
                              mealType.value === item?.instructions?.meal
                                ? " text-indigo-600"
                                : "",
                              "w-4 h-4 border-gray-300 ring-0"
                            )}
                            onChange={(e) =>
                              onChange(mealType.value, "meal", item)
                            }
                            checked={
                              mealType.value === item?.instructions?.meal
                            }
                          />
                          <span> {mealType.name}</span>
                        </span>
                      </label>
                    ))}
                  </div>
                </fieldset>

                <div className="block">
                  <span className="text-sm font-medium text-black cursor-pointer">
                    Note
                  </span>
                  <input
                    type="text"
                    onChange={(e) => onChange(e.target.value, "note", item)}
                    value={item?.instructions?.note}
                    className="w-full py-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
                  />
                </div>
              </div>

              <div className="grid gap-4 mt-4 lg:grid-cols-4"></div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default MedicineInstructions;
