import React, { useContext, useEffect, useState } from "react";
import { useNotification } from "../../NotificationContext";
import { LanguageContext } from "../../providers/LanguageProvider";
import { addReferral } from "../../apis/referral";

const AddReferral: React.FC<{ closeModal: () => void; }> = ({
  closeModal,
  //   editableId,
}) => {
  const defalutReferral = {
    referredName: "",
    referredEmail: "",
    referredPhone: "",
    status: "pending",
  };
  const [referral, setReferral] = useState(defalutReferral);
  const { showNotification } = useNotification();
  const [errors, setErrors] = useState<any>();
  const { translate: t } = useContext(LanguageContext);

  const saveReferral = () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    addReferral({ ...referral }).then((res) => {
      showNotification('success', 'Referral added successfully', '', 'topRight');
      closeModal();
    });
  };
  const cancel = () => {
    closeModal();
  };

  const validateForm = () => {
    const validationErrors: any = {};
    if (referral?.referredName === "") {
      validationErrors.referredName = "* Referred Name is required";
    }

    if (referral?.referredEmail === "") {
        validationErrors.referredEmail = "* Referred Email is required";
      }

      if (referral?.referredPhone === "") {
        validationErrors.referredPhone = "* Referred phone is required";
      }else if (referral?.referredPhone && referral?.referredPhone.length !== 10) {
        validationErrors.referredPhone = "phone number should be 10 digits";
      }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  return (
    <div className="">
      <span className="font-medium">{t("add")} {t("referrals")}</span>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("referred_name")}
        </label>
        <div className="mt-1">
          <input
            id="about"
            name="about"
            type="text"
            value={referral?.referredName}
            onChange={(e) =>
              setReferral({ ...referral, referredName: e.target.value })
            }
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        {errors && errors.referredName && (
          <span className="text-sm text-red-500 leading-0">
            {errors.referredName}
          </span>
        )}
      </div>

      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("referred_phone")}
        </label>
        <div className="mt-1">
          <input
            id="about"
            name="about"
            type="text"
            maxLength={10}
            value={referral?.referredPhone}
            onChange={(e) =>
              setReferral({ ...referral, referredPhone: e.target.value })
            }
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        {errors && errors.referredPhone && (
          <span className="text-sm text-red-500 leading-0">
            {errors.referredPhone}
          </span>
        )}
      </div>

      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("referred_email")}
        </label>
        <div className="mt-1">
          <input
            id="about"
            name="about"
            type="text"
            value={referral?.referredEmail}
            onChange={(e) =>
              setReferral({ ...referral, referredEmail: e.target.value })
            }
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        {errors && errors.referredEmail && (
          <span className="text-sm text-red-500 leading-0">
            {errors.referredEmail}
          </span>
        )}
      </div>

      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("status")}
        </label>
        <div className="mt-1">
          <input
            id="about"
            name="about"
            type="text"
            value={referral?.status}
            onChange={(e) =>
              setReferral({ ...referral, status: e.target.value })
            }
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

      </div>

      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => saveReferral()}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          {t("save")}
        </button>
        <button
          onClick={() => cancel()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {t("cancel")}
        </button>
      </div>
    </div>
  );
};
export default AddReferral;
