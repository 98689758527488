import { useState } from "react";
import { useNotification } from "../../NotificationContext";
import { saveVisit } from "../../apis/visit";
import { format } from "date-fns";
import { checkDoctorAvailability } from "../../apis/event";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Visit } from "../../interfaces/Visit";

type FollowUpVisitType = Omit<
  Visit,
  "flag" | "clinicVisitType" | "advice" | "amountPaid" | "visitFiles"
>;

const AddFollowUpVisit: React.FC<{
  closeModal: () => void;
  patientId: any;
  userId: number;
  parentId: any;
  clinicVisitTypeId: any;
  clinicVisitTypeLayoutId: any;
}> = ({
  closeModal,
  patientId,
  userId,
  parentId,
  clinicVisitTypeId,
  clinicVisitTypeLayoutId,
}) => {
  const [visit, setVisit] = useState<FollowUpVisitType>({
    userId,
    isInpatient: true,
    patientId: patientId,
    clinicVisitTypeId: clinicVisitTypeId,
    parentId: parentId,
    clinicVisitTypeLayoutId: clinicVisitTypeLayoutId,
    meta: {
      height: "",
      weight: "",
    },
    fee: 0,
    discount: 0,
    medicines: [],
    services: [],
    symptoms: [],
    provisionalDiagnosis: [],
    finalDiagnosis: [],
    date: format(new Date(), "yyy-MM-dd HH:mm").toString(),
  });
  const { showNotification } = useNotification();

  const createFollowUpVisit = async () => {
    const result = await checkDoctorAvailability({
      userId: visit.userId,
      date: new Date(visit.date).toISOString(),
    });
    if (result.data.availability) {
      saveVisit({ ...visit, date: new Date(visit.date).toISOString() }).then(
        () => {
          showNotification(
            "success",
            "Appointment Added successfully",
            "",
            "topRight"
          );
          closeModal();
        }
      );
    } else {
      showNotification(
        "error",
        "Error",
        "Doctor is unavailable. Please select another date or time.",
        "topRight"
      );
    }
  };

  const handleDateSelect = (date: any) => {
    setVisit({
      ...visit,
      date: format(new Date(date), "yyy-MM-dd HH:mm").toString(),
    });
  };

  const handleColor = (time: Date) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

  return (
    <div className="">
      <span className="font-medium">Follow Up Visit</span>

      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Date
        </label>
        <div className="mt-1">
          <DatePicker
            className="w-full block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            showTimeSelect
            selected={new Date(visit.date)}
            onChange={(date) => handleDateSelect(date)}
            popperPlacement="bottom-start"
            portalId="root-panel"
            dateFormat="dd/MM/yyyy hh:mm a"
            timeClassName={handleColor}
          />
        </div>
      </div>

      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => createFollowUpVisit()}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          Save
        </button>
        <button
          onClick={() => closeModal()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
export default AddFollowUpVisit;
