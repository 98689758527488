import api from "../middlware/api";
import { BASE_URL } from "../constants";
import { camelToSnake } from "../utils/CamelToSnake";

export const savePatient = (payload: any) => {
  const { registrationNumber, ...rest } = payload;
  return api.post(`${BASE_URL}/api/v1/patients`, camelToSnake(rest));
};

export const updatePatient = (payload: any) => {
  return api.put(
    `${BASE_URL}/api/v1/patients/${payload.id}`,
    camelToSnake(payload)
  );
};

export const getPatient = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/patients/${payload}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPatients = (params: any) => {
  let query = new URLSearchParams();
  for (let key in params) {
    query.set(key, params[key]);
  }
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/patients?${query.toString()}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const exportPatients = (params: any) => {
  let query = new URLSearchParams();
  for (let key in params) {
    query.set(key, params[key]);
  }
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/export/patients?${query.toString()}`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const verifyPatient = (phone: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/patients/${phone}/verify`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ShowPatient = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/show-patients/${payload}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createPatient = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/create-patients`, camelToSnake(payload))
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDoctors = (params: any) => {
  return new Promise((resolve, reject) => {
    let query = new URLSearchParams();
    for (let key in params) {
      query.set(key, params[key]);
    }
    api
      .get(`${BASE_URL}/api/v1/doctors?${query.toString()}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPatientsList = (params: any) => {
  let query = new URLSearchParams();
  for (let key in params) {
    query.set(key, params[key]);
  }
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/patients-list?${query.toString()}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const generateOtp = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/api/v1/generate/otp`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const verifyOtp = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/api/v1/verify/${payload.phone}/otp/${payload.otp}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};