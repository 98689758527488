import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { getServices } from "../../apis/service";
import {
  changeVisitData,
  selectVisit,
  UpdateVisitField,
} from "../../features/visit/visitSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Service } from "../../interfaces/Service";
import {
  PrescriptionDetails,
  Visit,
  VisitService,
} from "../../interfaces/Visit";
import { Editor, EditorState } from "react-draft-wysiwyg";
import { convertToRaw } from "draft-js";
import Modal from "../../components/Modal";
import Prescription from "./Prescription";
import MedicineSelect from "./MedicineSelect";
import MedicineInstructions from "./MedicineInstructions";

const Treatment: React.FC<{
  preview?: boolean;
}> = ({ preview = false }) => {
  const visit = useAppSelector(selectVisit);
  const dispatch = useAppDispatch();
  const [services, setServices] = useState<Array<Service>>([]);
  const [selectedServices, setSelectedServices] = useState<Array<any>>([]);
  const [isPrescriptionVisible, setIsPrescriptionVisible] = useState(false);
  const [prescriptionDetails, setPrescriptionDetails] =
    useState<PrescriptionDetails>();

  const [AdviceEditorState, setAdviceEditorState] = useState(
    EditorState?.createEmpty()
  );

  useEffect(() => {
    fetchServices();
  }, []);

  useEffect(() => {
    if (visit && visit.services.length > 0) {
      const selectedServices = visit.services.map((i) => i.id);
      setSelectedServices(selectedServices);
    }
  }, [visit]);

  const updateField = (fieldName: keyof Visit, value: any) => {
    const payload: UpdateVisitField = { fieldName, value };
    dispatch(changeVisitData(payload));
  };

  const fetchServices = () => {
    getServices({}).then((res: any) => {
      setServices(res.data.data);
    });
  };

  const handleServiceChange = (value: any) => {
    let visitServices: Array<VisitService> = [];
    const latestElement = value.slice(-1)[0];
    const [ifExists] = selectedServices.filter((i) => i === latestElement);
    if (ifExists) {
      setSelectedServices(value);
    } else {
      setSelectedServices([...selectedServices, latestElement]);
    }
    value.forEach((i: number) => {
      const [service] = services.filter((service) => service.id === i);
      if (service && service.id) {
        visitServices.push({
          id: service.id,
          fee: service.fee,
          name: service.name,
          quantity: 1,
        });
      }
    });
    updateField("services", visitServices);
  };

  const onEditorStateChange = (
    editorState: EditorState,
    field: keyof Visit,
    setState: React.Dispatch<React.SetStateAction<EditorState>>
  ) => {
    setState(editorState);
    const contentState = editorState.getCurrentContent();
    const contentStateString = JSON.stringify(convertToRaw(contentState));
    updateField(field, contentStateString);
  };

  const generatePrescription = () => {
    setIsPrescriptionVisible(true);
    if (visit.patient) {
      const details: PrescriptionDetails = {
        symptoms: [],
        finalDiagnosis: visit.finalDiagnosis.map((i) => i.label).join(","),
        provisionalDiagnosis: visit.provisionalDiagnosis
          .map((i) => i.label)
          .join(","),
        medicines: visit.medicines,
        advice: visit.advice,
        patient: visit.patient,
        visitId: visit.id,
      };
      setPrescriptionDetails(details);
    }
  };

  return (
    <div>
      <div className="grid gap-4 p-4 lg:grid-cols-2">
        <div className="block ">
          <span className="text-sm font-medium text-gray-700">
            Select Medicine
          </span>
          <MedicineSelect visit={visit} onCreate={() => {}} />
        </div>
        <div className="block ">
          <span className="text-sm font-medium text-gray-700">Advice</span>

          <Editor
            editorState={AdviceEditorState}
            toolbarClassName="border border-gray-300 rounded-md"
            wrapperClassName="h-auto"
            editorClassName="h-full border border-gray-300 rounded-md p-2 "
            onEditorStateChange={(editorState) =>
              onEditorStateChange(editorState, "advice", setAdviceEditorState)
            }
            toolbar={{
              options: ["inline", "list", "blockType"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
            }}
          />
        </div>
      </div>
      <div className="flex items-center justify-between px-4">
        <div className="block w-1/2">
          <span className="text-sm font-medium text-gray-700">
            Select Service
          </span>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Select services"
            onChange={handleServiceChange}
            value={selectedServices}
            options={services.map((i) => ({ value: i.id, label: i.name }))}
          />
        </div>
        <div className="flex items-center justify-center">
          <button
            onClick={() => generatePrescription()}
            className="px-3 py-2 text-sm text-white rounded-md bg-primary"
          >
            Prescription View
          </button>
        </div>
      </div>
      <MedicineInstructions visit={visit} />

      {isPrescriptionVisible && prescriptionDetails && (
        <Modal
          width="max-w-2xl"
          title="Prescription Preview"
          onClose={() => setIsPrescriptionVisible(false)}
          modalContent={
            <Prescription preview prescriptionDetails={prescriptionDetails} />
          }
        />
      )}
    </div>
  );
};

export default Treatment;
