import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  UpdateFieldPayload,
  UpdateHistoryData,
  changeHistoryData,
  changePatient,
  changePatientData,
  selectPatient,
} from "../../features/patient/patientSlice";
import { HistoryData, Patient } from "../../interfaces/Patient";
import { Select } from "antd";
import { getDisorders, saveDisorder } from "../../apis/disorder";
import { Disorder } from "../../interfaces/Disorder";
import { useParams } from "react-router-dom";
import { getPatient } from "../../apis/patient";
import { LanguageContext } from "../../providers/LanguageProvider";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

const AdvanceProfile: React.FC<{ errors: Patient | null }> = ({ errors }) => {
  const { translate: t } = useContext(LanguageContext);

  const patient = useAppSelector(selectPatient);
  const [allergies, setAllergies] = useState<Array<Disorder>>([]);
  const [surgeries, setSurgeries] = useState<Array<Disorder>>([]);
  const [diseases, setDiseases] = useState<Array<Disorder>>([]);
  const dispatch = useAppDispatch();
  const params = useParams();

  const [medicinesEditorState, setMedicinesEditorState] = useState(
    EditorState?.createEmpty()
  );
  const [familyHistoryEditorState, setFamilyHistoryEditorState] = useState(
    EditorState?.createEmpty()
  );

  useEffect(() => {
    if (params && params.patientId) {
      getPatient(params.patientId).then((res: any) => {
        const response = res.data.data;
        if (response?.historyData?.ongoingMedicines) {
          convertTextToEditor(
            response?.historyData?.ongoingMedicines,
            setMedicinesEditorState
          );
        }
        if (response?.historyData?.familyHistory) {
          convertTextToEditor(
            response?.historyData?.familyHistory,
            setFamilyHistoryEditorState
          );
        }
        dispatch(changePatient(res.data.data));
      });
    }
    fetchDisorders();
  }, [dispatch, params]);

  const updateField = (
    fieldName: keyof Patient,
    value: string | number | boolean
  ) => {
    const payload: UpdateFieldPayload = { fieldName, value };
    dispatch(changePatientData(payload));
  };

  const updateHistoryField = (
    fieldName: keyof HistoryData,
    value: string | null | Array<any>
  ) => {
    const payload: UpdateHistoryData = { fieldName, value };
    dispatch(changeHistoryData(payload));
  };

  const fetchDisorders = () => {
    getDisorders({ per_page: 500, type: "allergy" }).then((res: any) => {
      setAllergies(res.data.data);
    });
    getDisorders({ per_page: 500, type: "surgery" }).then((res: any) => {
      setSurgeries(res.data.data);
    });
    getDisorders({ per_page: 500, type: "disease" }).then((res: any) => {
      setDiseases(res.data.data);
    });
  };
  const handleAllergyChange = (value: []) => {
    if (value && value.length > 0) {
      let selectedAllergies: Array<number> = patient.historyData.allergies;
      const latestElement = value.slice(-1)[0];
      if (typeof latestElement !== "number") {
        addNewDisorder(latestElement, "allergy", "allergies");
      } else {
        if (selectedAllergies) {
          const [ifExists] = selectedAllergies.filter(
            (i) => i === latestElement
          );
          if (ifExists) {
            selectedAllergies = value;
          } else {
            selectedAllergies = [...selectedAllergies, latestElement];
          }
        }
      }
      updateHistoryField("allergies", selectedAllergies);
    } else {
      updateHistoryField("allergies", []);
    }
  };

  const handleDiseaseChange = (value: []) => {
    if (value && value.length > 0) {
      let selectedDiseases: Array<number> =
        patient.historyData.previousDiseases;
      const latestElement = value.slice(-1)[0];
      if (typeof latestElement !== "number") {
        addNewDisorder(latestElement, "disease", "previousDiseases");
      } else {
        if (selectedDiseases) {
          const [ifExists] = selectedDiseases.filter(
            (i) => i === latestElement
          );
          if (ifExists) {
            selectedDiseases = value;
          } else {
            selectedDiseases = [...selectedDiseases, latestElement];
          }
        }
      }
      updateHistoryField("previousDiseases", selectedDiseases);
    } else {
      updateHistoryField("previousDiseases", []);
    }
  };

  const handleSurgeryChange = (value: any) => {
    if (value && value.length > 0) {
      let selectedSurgeries: Array<number> =
        patient.historyData.previousSurgeries;
      const latestElement = value.slice(-1)[0];
      if (typeof latestElement !== "number") {
        addNewDisorder(latestElement, "surgery", "previousSurgeries");
      } else {
        if (selectedSurgeries) {
          const [ifExists] = selectedSurgeries.filter(
            (i) => i === latestElement
          );
          if (ifExists) {
            selectedSurgeries = value;
          } else {
            selectedSurgeries = [...selectedSurgeries, latestElement];
          }
        }
      }
      updateHistoryField("previousSurgeries", selectedSurgeries);
    } else {
      updateHistoryField("previousSurgeries", []);
    }
  };

  const addNewDisorder = async (
    name: string,
    type: string,
    historyDataKey: keyof HistoryData
  ) => {
    await saveDisorder({ name, type }).then(async (res: any) => {
      let existingData: Array<number> = (patient.historyData as any)[
        historyDataKey
      ];
      await fetchDisorders();
      if (historyDataKey) {
        updateHistoryField(historyDataKey, [...existingData, res.data.id]);
      }
      existingData = [];
    });
  };

  const onEditorStateChange = (
    editorState: EditorState,
    field: keyof HistoryData,
    setState: React.Dispatch<React.SetStateAction<EditorState>>
  ) => {
    setState(editorState);
    const contentState = editorState.getCurrentContent();
    const contentStateString = JSON.stringify(convertToRaw(contentState));
    updateHistoryField(field, contentStateString);
  };

  const convertTextToEditor = (
    text: any,
    setState: React.Dispatch<React.SetStateAction<EditorState>>
  ) => {
    if (text) {
      const contentState = convertFromRaw(JSON.parse(text));
      const initialEditorState = EditorState?.createWithContent(contentState);
      setState(initialEditorState);
    }
  };

  return (
    <div>
      <div className="bg-white">
        <div className="grid w-full gap-4 p-4 lg:grid-cols-2">
          <div className="block">
            <fieldset className="">
              <span className="text-sm font-medium text-gray-700">
                {t("patient_habits")}
              </span>

              <div className="grid grid-cols-2">
                <div className="block">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-6">
                      <input
                        name="habits"
                        type="checkbox"
                        checked={patient.isAlcoholic}
                        onChange={(e) =>
                          updateField("isAlcoholic", !patient.isAlcoholic)
                        }
                        className="w-4 h-4 border-gray-300 rounded text-primary focus:ring-primary"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label className="font-medium text-gray-900">
                        {t("alcohol")}
                      </label>
                    </div>
                  </div>
                  <div className="relative flex items-start mt-2">
                    <div className="flex items-center h-6">
                      <input
                        name="habits"
                        type="checkbox"
                        checked={patient.isTobacco}
                        onChange={(e) =>
                          updateField("isTobacco", !patient.isTobacco)
                        }
                        className="w-4 h-4 border-gray-300 rounded text-primary focus:ring-primary"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="candidates"
                        className="font-medium text-gray-900"
                      >
                        {t("tobacco")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="block">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-6">
                      <input
                        name="habits"
                        type="checkbox"
                        checked={patient.isSmoking}
                        onChange={(e) =>
                          updateField("isSmoking", !patient.isSmoking)
                        }
                        className="w-4 h-4 border-gray-300 rounded text-primary focus:ring-primary"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="offers"
                        className="font-medium text-gray-900"
                      >
                        {t("smoking")}
                      </label>
                    </div>
                  </div>
                  <div className="relative flex items-start mt-2">
                    <div className="flex items-center h-6">
                      <input
                        name="habits"
                        type="checkbox"
                        checked={patient.isDrugs}
                        onChange={(e) =>
                          updateField("isDrugs", !patient.isDrugs)
                        }
                        className="w-4 h-4 border-gray-300 rounded text-primary focus:ring-primary"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="offers"
                        className="font-medium text-gray-900"
                      >
                        {t("drugs")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("previous_surgeries")}
            </span>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Enter surgeries"
              value={patient.historyData.previousSurgeries}
              onChange={handleSurgeryChange}
              options={surgeries.map((i) => ({ value: i.id, label: i.name }))}
            />
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("allergies")}
            </span>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Enter allergies"
              value={patient.historyData.allergies}
              onChange={handleAllergyChange}
              options={allergies.map((i) => ({ value: i.id, label: i.name }))}
            />
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("previous_disease")}
            </span>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Enter diseases"
              value={patient.historyData.previousDiseases}
              onChange={handleDiseaseChange}
              options={diseases.map((i) => ({ value: i.id, label: i.name }))}
            />
          </div>
          <div className="block">
            <label
              htmlFor="comment"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("ongoing_medicines")}
            </label>
            <div className="mt-2">
              <Editor
                editorState={medicinesEditorState}
                toolbarClassName="border border-gray-300 rounded-md"
                wrapperClassName="h-auto"
                editorClassName="h-full border border-gray-300 rounded-md p-2 "
                onEditorStateChange={(editorState) =>
                  onEditorStateChange(
                    editorState,
                    "ongoingMedicines",
                    setMedicinesEditorState
                  )
                }
                toolbar={{
                  options: ["inline", "list", "blockType"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                }}
              />
            </div>
          </div>
          <div className="block">
            <label
              htmlFor="comment"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("family_history")}
            </label>
            <div className="mt-2">
              <Editor
                editorState={familyHistoryEditorState}
                toolbarClassName="border border-gray-300 rounded-md"
                wrapperClassName="h-auto"
                editorClassName="h-full border border-gray-300 rounded-md p-2 "
                onEditorStateChange={(editorState) =>
                  onEditorStateChange(
                    editorState,
                    "familyHistory",
                    setFamilyHistoryEditorState
                  )
                }
                toolbar={{
                  options: ["inline", "list", "blockType"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvanceProfile;
