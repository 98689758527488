import React, { useEffect, useState } from "react";
import MultiSelect from "../../components/MultiSelect";
import { Option } from "../../interfaces/MultiSelectTypes";
import { addSymptom } from "../../apis/symptom";
import { Visit } from "../../interfaces/Visit";
import { updateField } from "../../utils/StateUtil";
import { useAppDispatch } from "../../hooks";
import { getDisorders } from "../../apis/disorder";
type DiseaseSelectProps = {
  visit: Visit;
  propertyType: "provisionalDiagnosis" | "finalDiagnosis";
  diseaseType: "disease" | "surgery" | "allergy";
};
const DiseaseSelect: React.FC<DiseaseSelectProps> = ({
  visit,
  propertyType,
  diseaseType,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [diseases, setDiseases] = useState<Array<Option>>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchDiseases();
  }, []);

  const handleSearch = (search: string) => {
    setSearchTerm(search);
    fetchDiseases(search);
  };

  const fetchDiseases = async (search: string = "") => {
    try {
      const {
        data: { data: response },
      } = await getDisorders({ name: search });
      const symptomData = response.map((i: Record<string, number>) => {
        return {
          label: i.name,
          value: i.id,
        };
      });
      setDiseases(symptomData);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleDiseaseSelect = (option: Option) => {
    if (
      !visit[propertyType].some((selected) => selected.value === option.value)
    ) {
      updateField(propertyType, [...visit[propertyType], option], dispatch);
    }
  };

  const handleDiseaseRemoval = (option: Option) => {
    const updatedSymptoms = visit.symptoms.filter(
      (selected) => selected.value !== option.value
    );
    updateField(propertyType, updatedSymptoms, dispatch);
  };

  const createNew = async (symptom: string) => {
    try {
      const { data } = await addSymptom({ name: symptom });
      updateField(
        propertyType,
        [
          ...visit[propertyType],
          {
            label: data.name,
            value: data.id,
          },
        ],
        dispatch
      );
      setSearchTerm("");
      fetchDiseases();
    } catch (error) {}
  };

  return (
    <MultiSelect
      onAddNewOption={createNew}
      selectedOptions={visit[propertyType]}
      options={diseases}
      isOpen={isOpen}
      searchTerm={searchTerm}
      onSearch={handleSearch}
      onOptionSelect={handleDiseaseSelect}
      onOptionRemove={handleDiseaseRemoval}
      toggleDropdown={toggleDropdown}
    />
  );
};

export default DiseaseSelect;
