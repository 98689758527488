import React, { useEffect, useRef, useState } from "react";

// Define the option type
type Option = {
  label: string;
  value: string;
};

interface MultiSelectProps {
  selectedOptions: Option[];
  options: Option[];
  isOpen: boolean;
  searchTerm: string;
  onSearch: (search: string) => void; // Function to handle search, passed from the parent
  onOptionSelect: (option: Option) => void; // Function to handle option selection
  onOptionRemove: (option: Option) => void; // Function to handle removing selected options
  onAddNewOption: (newOptionLabel: string) => void;
  toggleDropdown: () => void; // Function to toggle dropdown visibility
  renderOption?: (option: Option) => React.ReactNode; // Optional custom option renderer
  placeholder?: string; // Optional placeholder text
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  selectedOptions,
  options,
  isOpen,
  searchTerm,
  onSearch,
  onOptionSelect,
  onOptionRemove,
  toggleDropdown,
  onAddNewOption,
  renderOption,
  placeholder = "Select options...",
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Handle clicking outside the dropdown to collapse it
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      if (isOpen) toggleDropdown(); // Collapse the dropdown if clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="w-full">
      <div className="relative" ref={dropdownRef}>
        {/* Selected Options */}
        <div
          className="flex flex-wrap gap-2 p-1 border border-gray-300 rounded-md"
          onClick={toggleDropdown}
        >
          {selectedOptions.length > 0 ? (
            selectedOptions.map((option) => (
              <div
                key={option.value}
                className="flex items-center px-2 space-x-1 text-blue-700 bg-blue-100 rounded-md"
              >
                <span>{option.label}</span>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onOptionRemove(option);
                  }}
                  className="text-gray-500"
                >
                  &times;
                </button>
              </div>
            ))
          ) : (
            <span className="text-gray-400">{placeholder}</span>
          )}
        </div>

        {/* Dropdown Options */}
        {isOpen && (
          <div className="absolute left-0 z-10 w-full mt-2 overflow-y-auto bg-white border border-gray-300 rounded-md shadow-md max-h-48">
            {/* Search Input */}
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => onSearch(e.target.value)}
              className="w-full px-3 py-2 border-b border-gray-300 focus:outline-none"
              placeholder="Search..."
            />

            {/* Options List */}
            <ul className="overflow-y-auto max-h-36">
              {options.map((option) => (
                <li
                  key={option.value}
                  className="px-4 py-1 font-semibold cursor-pointer hover:bg-blue-100"
                  onClick={() => onOptionSelect(option)}
                >
                  {renderOption ? renderOption(option) : option.label}
                </li>
              ))}
              {searchTerm &&
                !options.some((opt) => opt.label === searchTerm) && (
                  <li
                    className="p-2 text-blue-600 cursor-pointer hover:bg-blue-100"
                    onClick={() => onAddNewOption(searchTerm)}
                  >
                    Add "{searchTerm}"
                  </li>
                )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelect;
