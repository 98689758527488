import React, { useEffect, useRef, useState } from "react";
import { PrescriptionDetails } from "../../interfaces/Visit";
import { formatString } from "../../utils/StringUtil";
import { User } from "../../interfaces/User";
import whatsAppIcon from "../../assets/images/icons/whatsapp.svg";
import html2canvas from "html2canvas";
import { sendPrescription } from "../../apis/visit";

type PrescriptionProps = {
  preview?: boolean;
  prescriptionDetails: PrescriptionDetails;
};
const Prescription: React.FC<PrescriptionProps> = ({
  preview,
  prescriptionDetails,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  const [instructions, setInstructions] = useState<Array<string>>([]);
  const user: User | null = JSON.parse(
    window.localStorage.getItem("user") || "{}"
  );

  useEffect(() => {
    if (prescriptionDetails.advice) {
      const adviceJson = JSON.parse(prescriptionDetails.advice);
      const data = adviceJson.blocks.map((i: Record<string, string>) => i.text);
      setInstructions(data);
    }
  }, [prescriptionDetails]);

  const takeScreenshot = () => {
    if (divRef.current) {
      html2canvas(divRef.current).then((canvas) => {
        // You can append the screenshot to the DOM
        document.body.appendChild(canvas);

        // Or convert the canvas to an image data URL
        const imgData = canvas.toDataURL("image/png");
        const blob = dataURLToBlob(imgData);
        const formData = new FormData();
        formData.append("image", blob, "image.png");
        try {
          sendPrescription(formData, prescriptionDetails.visitId);
        } catch (error) {
          console.log(error);

          throw new Error("Error while sending prescription");
        }
      });
    }
  };

  const dataURLToBlob = (dataURL: string): Blob => {
    const [header, data] = dataURL.split(",");
    const mimeMatch = header.match(/:(.*?);/);
    const mime = mimeMatch ? mimeMatch[1] : "application/octet-stream"; //
    const binaryString = atob(data);
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
    return new Blob([uint8Array], { type: mime });
  };

  return (
    <>
      {user ? (
        <>
          <div
            ref={divRef}
            className="flex flex-col max-w-4xl mx-auto bg-white border rounded-md prescription"
          >
            <div className="p-4 border-b-2 basis-1/6 header">
              <div className="grid grid-cols-2">
                <div className="flex flex-col">
                  <span className="text-base">{user.activeClinic.name}</span>
                  <span className="text-sm capitalize">
                    {user.activeClinic?.address}
                  </span>
                </div>
                <div className="flex flex-col text-right">
                  <span className="text-base">
                    {user?.firstName} {user?.lastName}
                  </span>
                  <span className="text-sm">{user.degree}</span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 p-4 border-b-2 basis-1/5 sm:grid-cols-4">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900 capitalize">
                  {prescriptionDetails?.patient?.firstName}{" "}
                  {prescriptionDetails?.patient?.lastName}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Gender</dt>
                <dd className="mt-1 text-sm text-gray-900 capitalize">
                  {prescriptionDetails?.patient?.gender}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Age</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {prescriptionDetails?.patient?.age}
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Phone</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {prescriptionDetails?.patient?.phone}
                </dd>
              </div>
            </div>

            <div className="px-4 py-2 basis-1/2 body">
              <div className="flex flex-col">
                <img src="/rx.png" className="w-12" alt="" />
              </div>
              <div className="py-2">
                <span className="font-semibold">Symptoms: </span>
                {/* {prescriptionDetails.symptoms.join(",")} */}
              </div>
              <div>
                {prescriptionDetails.finalDiagnosis ? (
                  <div>
                    <strong>Final Diagnosis: </strong>{" "}
                    {prescriptionDetails.finalDiagnosis}
                  </div>
                ) : prescriptionDetails.provisionalDiagnosis ? (
                  <div>
                    <strong>Provisional Diagnosis: </strong>{" "}
                    {prescriptionDetails.provisionalDiagnosis}
                  </div>
                ) : null}
              </div>
              {/* {JSON.stringify(prescriptionDetails)} */}
              {prescriptionDetails.medicines &&
                prescriptionDetails.medicines?.length > 0 && (
                  <>
                    <span className="font-semibold">Medicines: </span>
                    <table className="w-full mt-2 border">
                      <thead className="border ">
                        <th className="font-semibold">Name</th>
                        <th className="font-semibold">Dosage</th>
                        <th className="font-semibold text-right">Notes</th>
                      </thead>
                      <tbody>
                        {prescriptionDetails.medicines?.map(
                          (medicine, index) => (
                            <tr className="border-b">
                              <td className="capitalize">
                                {index + 1}
                                {") "}
                                {medicine.instructions.name}
                              </td>
                              <td>
                                {medicine.instructions.morning} -{" "}
                                {medicine.instructions.afternoon} -{" "}
                                {medicine.instructions.night}
                                <br />
                                {formatString(medicine.instructions.meal)}
                              </td>
                              <td className="text-right">
                                ----{medicine.instructions.duration}{" "}
                                <span className="capitalize ">
                                  {medicine.instructions.durationPeriod}
                                  <br />
                                  {medicine.instructions.note}
                                </span>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </>
                )}
              {instructions.length > 0 && (
                <div className="py-2 mt-2 ">
                  <span className="font-semibold">Advice: </span>
                  <ol className="pl-4 list-decimal">
                    {instructions.map((i) => (
                      <li>{i}</li>
                    ))}
                  </ol>
                </div>
              )}
              <div className="grid grid-cols-1 p-2 sm:grid-cols-4"></div>
            </div>
            <div className="mt-auto text-center basis-1/5 foote">
              <p className="pt-2 text-xs ">
                This is digitally generated, requires no signature
              </p>
            </div>
          </div>
          <div className="flex justify-end gap-2 pt-2">
            <button className="px-3 py-1.5 text-white rounded-md bg-primary">
              Print
            </button>
            <button
              onClick={() => takeScreenshot()}
              className="px-3 py-1.5 text-black rounded-md border flex items-center gap-2"
            >
              <span>Send on WhatsApp</span>
              <span>
                <img src={whatsAppIcon} className="w-6 h-6" />
              </span>
            </button>
          </div>
        </>
      ) : (
        <div className="flex justify-center text-center">
          <span className="pt-20">Loading ...</span>
        </div>
      )}
    </>
  );
};

export default Prescription;
