import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { Patient, HistoryData } from "../../interfaces/Patient";

// Define a type for the slice state

export interface PatientState {
  patient: Patient;
  currentStep: number;
}

export interface UpdateFieldPayload {
  fieldName: keyof Patient;
  value: string | number | boolean;
}

export interface UpdateHistoryData {
  fieldName: keyof HistoryData,
  value: Array<any> | string | null
}

// Define the initial state using that type
const initialState: PatientState = {
  patient: {
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    bloodGroup: "",
    gender: "male",
    age: "",
    birthDate: "",
    address: "",
    email: "",
    comments: "",
    registrationNumber: "",
    isTobacco: false,
    isSmoking: false,
    isAlcoholic: false,
    isDrugs: false,
    drugComments: "",
    referredBy: "",
    historyData: {
      familyHistory: "",
      previousSurgeries: [],
      previousDiseases: [],
      allergies: [],
      ongoingMedicines: "",
    },
  },
  currentStep: 1,
};

export const patientSlice = createSlice({
  name: "patient",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    changeStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    changePatientData: (state, action: PayloadAction<UpdateFieldPayload>) => {
      const { fieldName, value } = action.payload;
      (state.patient as any)[fieldName] = value;
    },
    changeHistoryData: (state, action: PayloadAction<UpdateHistoryData>) => {
      const { fieldName, value } = action.payload;
      (state.patient.historyData as any)[fieldName] = value;
    },
    changePatient: (state, action: PayloadAction<Patient>) => {
      state.patient = action.payload;
    },
    setInitialPatient: (state) => {
      state.patient = initialState.patient;
    }
  },
});

export const {
  changePatientData,
  changeHistoryData,
  changeStep,
  changePatient,
  setInitialPatient
} = patientSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectStep = (state: RootState) => state.patientState.currentStep;
export const selectPatient = (state: RootState) => state.patientState.patient;

export default patientSlice.reducer;
