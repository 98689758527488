import React, { useEffect, useState } from "react";
import MultiSelect from "../../components/MultiSelect";
import { Option } from "../../interfaces/MultiSelectTypes";
import { Visit } from "../../interfaces/Visit";
import { updateField } from "../../utils/StateUtil";
import { useAppDispatch } from "../../hooks";
import { getMedicines } from "../../apis/medicine";
import { Medicine } from "../../interfaces/Medicine";
type MedicineSelectProps = {
  visit: Visit;
  onCreate: () => void;
};
const MedicineSelect: React.FC<MedicineSelectProps> = ({ visit, onCreate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [medicines, setMedicines] = useState<Array<Medicine>>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchMedicines();
  }, []);

  const handleSearch = (search: string) => {
    setSearchTerm(search);
    fetchMedicines(search);
  };

  const fetchMedicines = async (search: string = "") => {
    try {
      const {
        data: { data: response },
      } = await getMedicines({ name: search });
      setMedicines(response);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleMedicineSelect = (option: Option) => {
    if (
      !visit.medicines.some(
        (selected) => selected.medicineId.toString() === option.value
      )
    ) {
      const [medicine] = medicines.filter(
        (medicine) => medicine.id.toString() === option.value
      );

      updateField(
        "medicines",
        [
          ...visit.medicines,
          {
            medicineId: option.value,
            name: option.label,
            instructions: {
              name: option.label,
              molecule: medicine.molecule,
              form: medicine.form,
              morning: 0,
              afternoon: 0,
              evening: 0,
              night: 0,
              meal: "before_meal",
              note: "",
              quantity: 0,
              frequency: "daily",
              duration: "",
              power: "",
              unit: "",
              dosage: "",
              durationPeriod: "days",
            },
          },
        ],
        dispatch
      );
    }
  };

  const handleMedicineRemoval = (option: Option) => {
    const updatedMedicines = visit.medicines.filter(
      (selected) => selected.medicineId.toString() !== option.value
    );
    updateField("medicines", updatedMedicines, dispatch);
  };

  return (
    <MultiSelect
      onAddNewOption={onCreate}
      selectedOptions={visit.medicines.map((i) => ({
        label: i.name,
        value: i.medicineId.toString(),
      }))}
      options={medicines.map((i) => ({
        label: i.name,
        value: i.id.toString(),
      }))}
      isOpen={isOpen}
      searchTerm={searchTerm}
      onSearch={handleSearch}
      onOptionSelect={handleMedicineSelect}
      onOptionRemove={handleMedicineRemoval}
      toggleDropdown={toggleDropdown}
    />
  );
};

export default MedicineSelect;
